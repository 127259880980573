import React from "react";

import { useIntl } from "react-intl";
import { useFormik } from "formik";
import Head from "next/head";

import Icon from "../modules/common/components/Icon";
import LocalizedLink from "modules/layout/components/LocalizedLink";
import { useRouter } from "next/router";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

export default function NotFound({ searchQuery = "" }) {
  const intl = useIntl();
  const contactPageId = findPageIdByFilePath("/contact");
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      query: searchQuery,
    },
    onSubmit: ({ query }) => {
      router.push(`/search/${encodeURIComponent(query)}`);
    },
  });
  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "error_404_title",
            defaultMessage: "Da ging etwas schief...",
          })}
        </title>
      </Head>
      <div className="error-area d-flex align-items-center justify-content-center flex-column text-center">
        <div className="px-3">
          <div className="error-code">404</div>
          <h1 className="mt-0">
            {intl.formatMessage({
              id: "error_404_title",
              defaultMessage: "Da ging etwas schief...",
            })}
          </h1>
          <p className="mb-0">
            {intl.formatMessage({
              id: "error_404_content",
              defaultMessage:
                "Sorry, die Seite, die Sie gesucht haben, wurde nicht gefunden!",
            })}
          </p>
          <p className="mt-0">
            {intl.formatMessage({
              id: "error_404_search",
              defaultMessage:
                "Verwenden Sie die Suche, um Ihre gewünschte Information zu finden!",
            })}
          </p>
          <div className="search-bar my-4">
            <span className="search-wrap">
              <form
                className="position-relative w-lg-50 mx-auto"
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
                method="POST"
              >
                <input
                  name="query"
                  type="text"
                  id="search-desktop"
                  className="form-field border-0"
                  placeholder={intl.formatMessage({
                    id: "search",
                    defaultMessage: "Suchen",
                  })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.query}
                />
                <label htmlFor="search-desktop" className="d-none">
                  {intl.formatMessage({
                    id: "search",
                    defaultMessage: "Suchen",
                  })}
                </label>
                <button
                  type="submit"
                  className="no-button btn-search"
                  aria-label="Search"
                >
                  <Icon icon="search-1" fill="currentColor" />
                </button>
              </form>
            </span>
          </div>
          <div className="button-group justify-content-center mt-5">
            <LocalizedLink
              page={{ pageId: "/" }}
              className="button button--tertiary"
            >
              {intl.formatMessage({
                id: "back_to_home",
                defaultMessage: "zur Startseite",
              })}
            </LocalizedLink>
            <LocalizedLink
              page={{ pageId: contactPageId }}
              className="button button--tertiary mr-0"
            >
              {intl.formatMessage({
                id: "contact_us",
                defaultMessage: "Kontaktieren Sie uns",
              })}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </>
  );
}
